import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import { useWindowScroll, useWindowSize, useMount, useLocation, useScroll } from 'react-use'
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'

import { Layout, Section, Block, Slider } from '../components'
import { media } from '../styles/utils'
import {
    container,
    padding,
    colours,
    type,
    bgIcon,
    bgImage,
    buttonSmall,
    button,
} from '../styles/global'
import { parseACF, getImageCrop } from '../utils'
import ProgressiveImage from 'react-progressive-image'
import { motion, transform } from 'framer-motion'

// Check if window is defined (browser environment)
const isBrowser = typeof window !== 'undefined';

const useSafeHash = () => {
    if (isBrowser) {
        const { useHash } = require('react-use');
        return useHash();
    }
    return ['', () => {}];
};

const OurPractice = (props) => {
    const { x, y } = useWindowScroll()
    const { width, height } = useWindowSize()
    const [activeIndex, setActiveIndex] = useState(0)
    const location = useLocation();
    const [hash, setHash] = useSafeHash();
    const data = parseACF(useStaticQuery(query), 'allWordpressInfopages')
    const hasMounted = useRef(false);
    const prevHash = useRef(location.hash);
    const scrollRef = useRef(null);
    const { y: scrollY } = useScroll(scrollRef);

    // Utils

    const slugify = (text) => {
        return text
        .toLowerCase()
        .replace(/[^a-zA-Z0-9&]+/g, '-')
        .replace(/^-|-$/g, '')
    }

    const deslugify = (text) => {
        return text
        .replace(/-/g, ' ')
        .replace(/\b\w/g, (char) => char.toUpperCase())
    }

    const smoothScrollToElement = (id, offset) => {
        if (isBrowser) {
            const element = document.getElementById(id);
            if (element) {
                const y = element.getBoundingClientRect().top + window.pageYOffset - offset;
                window.scrollTo({ top: y, behavior: 'smooth' });
            }
        }
    };

    // Replace useEffect with useLayoutEffect for the initial scroll
    useLayoutEffect(() => {
        if (!hasMounted.current && hash && data?.tab_content) {
            const tabName = deslugify(hash.replace('#', ''))
            const tabIndex = data.tab_content.findIndex(item => slugify(item?.name) === slugify(tabName))
            
            if (tabIndex !== -1) {
                setActiveIndex(tabIndex)
                // Prevent default scroll behavior
                if (isBrowser) {
                    window.history.scrollRestoration = 'manual';
                    window.scrollTo(0, 0);
                }
                // Apply smooth scroll with offset
                setTimeout(() => smoothScrollToElement(slugify(tabName), 120), 100)
            }
            hasMounted.current = true;
        }
    }, [hash, data]);

    // Keep the existing useEffect for subsequent hash changes
    useEffect(() => {
        if (hasMounted.current && hash && hash !== prevHash.current && data?.tab_content) {
            const tabName = deslugify(hash.replace('#', ''))
            const tabIndex = data.tab_content.findIndex(item => slugify(item?.name) === slugify(tabName))
            
            if (tabIndex !== -1) {
                setActiveIndex(tabIndex)
                smoothScrollToElement(slugify(tabName), 120)
            }
            prevHash.current = hash;
        }
    }, [hash, data]);

    // Our Story

    const renderSlider = () => {
        if (!data.ourstory_carousel) return

        return (
            <SliderWrapper>
                <Slider
                    slides={data.ourstory_carousel}
                    sliderStyles={sliderStyles}
                    hoverCursors
                />
            </SliderWrapper>
        )
    }

    const renderOurStory = () => {
        return (
            <OurStory id="our-story">
                {data.ourstory_heading && (
                    <Heading>{data.ourstory_heading}</Heading>
                )}

                {data.ourstory_text && (
                    <Text
                        dangerouslySetInnerHTML={{ __html: data.ourstory_text }}
                    />
                )}

                {renderSlider()}
            </OurStory>
        )
    }

    // Content 

    const renderContentBlocks = (blocks) => {
        if(!blocks) return

        return blocks.map((item, i) => {

            <Text
                dangerouslySetInnerHTML={{ __html: item.heading }}
            />
        })
    }

    const renderContent = () => {
        if(!data?.tab_content) return 

        const renderTabNav = () => {
            if(!data?.tab_content) return

            return data?.tab_content.map((item, i) => {
                const tabSlug = slugify(item?.name);
                return (
                    <NavItem
                        key={i}
                        id={tabSlug}
                        active={activeIndex === i}
                        onClick={() => {
                            setActiveIndex(i);
                            setHash(`#${tabSlug}`);
                            smoothScrollToElement(tabSlug, 100);
                        }}
                    >
                        {item?.name}
                    </NavItem>
                )
            })
        }

        const renderTabContent = () => {
            if(!data?.tab_content) return
        
            return data?.tab_content.map((item, i) => (
                activeIndex == i && item.blocks.map((block, index) => (
                    <Block
                        layout={block.acf_fc_layout}
                        display={block.display}
                        key={index}
                        {...block}
                    />
                ))
            ));
        }

        return (
            <TabbedContent>
                <Navigation>{renderTabNav()}</Navigation>
                <Content>{renderTabContent()}</Content>
            </TabbedContent>
        )
    }

    // The Studio

    // const renderTheStudio = () => {
    //     const items =
    //         data.studio_images &&
    //         data.studio_images.map((item, i) => {
    //             return (
    //                 <ImageItem key={i}>
    //                     <Image
    //                         key={item.image?.sizes?.medium2}
    //                         src={item.image?.sizes?.medium2}
    //                     >
    //                         {(src, loading) => {
    //                             return (
    //                                 <BGImage
    //                                     image={src}
    //                                     style={{ opacity: loading ? 0 : 1 }}
    //                                 />
    //                             )
    //                         }}
    //                     </Image>
    //                 </ImageItem>
    //             )
    //         })

    //     return (
    //         <TheStudio id="studio">
    //             <Title>{data.studio_heading}</Title>
    //             <Wrap>{items}</Wrap>
    //         </TheStudio>
    //     )
    // }


    return (
        <div ref={scrollRef}>
            <Layout meta={data && data.seo}>
                <Container>
                    {renderOurStory()}
                    {renderContent()}
                    {/* {renderTheStudio()} */}
                </Container>
            </Layout>
        </div>
    )
}

// Shared Components

const Heading = styled.h1``
const Title = styled.h1``
const Text = styled.div``
const Wrap = styled.div``
const SliderWrapper = styled.div``
const Slide = styled.div``
const ImageItem = styled.div``
const Icon = styled.div``


// Shared

const SubHeading = styled.div``
const Button = styled.div``
const Navigation = styled.div``
const CardList = styled.div``
const Meta = styled.div``

// Utlity

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

// Layout

const Container = styled.div`
    ${container}
    ${padding}
	flex-direction: column;
    margin-top: 200px;
`

// Slider

const sliderStyles = css`
    overflow: visible;
    height: 60vw;

    ${media.phone`
		height: 256px;
	`}

    .slide {
        background-color: ${colours.red};
    }

    .image-wrapper {
        width: 100%;

        img {
            width: 100%;
            height: 100%;
            // min-height: 25vw;
            object-fit: cover;
            object-position: center;

            ${media.phone`
				height: 256px;
			`}
        }
    }
`

const OurStory = styled.div`
    margin-bottom: 150px;

    ${media.tablet`
		margin-bottom: 70px;
	`}

    ${Heading} {
        ${type.heading1};
        padding-bottom: 32px;

        ${media.tablet`
			padding-bottom: 20px;
		`}
    }

    ${Text} {
        ${type.body};
        max-width: 80%;

        ${media.tablet`
			max-width: 100%;	
		`}
    }

    .swiper-container {
        .slider-next {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 100;

            &:focus {
                outline: none;
            }
        }
    }

    ${Slide} {
        ${BGImage} {
            width: 100%;
			padding-top: calc(591.44 / 1127.34 * 100%);
        }
    }
`

const PrevNav = styled.div`
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    left: 0;
`
const NextNav = styled.div`
    position: absolute;
    width: 50px;
    height: 100%;
    top: 0;
    right: 0;
`

// The Studio

const TheStudio = styled.div`
    margin-bottom: 150px;

    ${media.tablet`
		margin-bottom: 70px;
	`}

    ${Title} {
        ${type.heading1};
        padding-bottom: 32px;

        ${media.tablet`
			padding-bottom: 20px;	
		`}
    }

    ${Wrap} {
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        justify-content: center;

        ${ImageItem} {
            padding-bottom: 72px;

            ${media.tablet`
				padding-bottom: 20px;
			`}

            &:nth-child(1) {
                width: 100%;

                ${BGImage} {
                    max-width: 913px;
                    min-height: 643px;
                    margin: 0 auto 0 30%;
                    ${bgIcon};

                    ${media.tablet`
                        min-height: 50vw;
					`}
                }
            }

            &:nth-child(2) {
                width: 45%;

                ${BGImage} {
                    max-width: 540px;
                    min-height: 670px;
                    ${bgIcon};

                    ${media.tablet`
                        min-height: 50vw;
					`}
                }
            }

            &:nth-child(3) {
                width: 45%;
                padding-top: 160px;

                ${media.tablet`
					width: 50%;
					padding-left: 20px;	
					padding-top: 60px;
				`}

                ${BGImage} {
                    max-width: 540px;
                    min-height: 670px;
                    border-radius: 100%;

                    ${media.tablet`
						min-height: 50vw;
					`}
                }
            }
        }
    }
`

// Tabbed Content

const TabbedContent = styled.div`
    ${Title} {
        ${type.heading1};
        padding-bottom: 32px;

        ${media.tablet`
			padding-bottom: 20px;
		`}
    }

    ${Navigation} {
        display: flex;

        ${media.tablet`
			flex-wrap: wrap;	
		`}
    }
`

const NavItem = styled.div`
    ${button};
    margin-right: 24px;
    background: ${colours.lightGreen};

    ${media.tablet`
		margin-right: 8px;	
		margin-bottom: 12px;	
	`}

    &:hover {
        &:before {
            display: none;
        }
    }

    ${(props) => {
        if (props.active == true)
            return css`
                background: ${colours.black};
                color: ${colours.white};
                border: 3px solid ${colours.black};

                &:hover {
                    background: ${colours.black};
                }
            `
    }}
`

const Content = styled.div`
    border-top: 3px solid ${colours.black};
    margin-top: 44px;
    padding-top: 32px;
    padding-bottom: 80px;

    ${media.tablet`
		margin-top: 20px;
		border-top: 2px solid ${colours.black};
        padding-bottom: 40px;
	`}

    .block_heading div {
        ${type.heading3};
        font-family: 'Souvenir', serif;
    }

    .block_text {
        width: 80%;

        ${media.tablet`
            width: 100%;	
        `}
    }

    .block_image div {
        align-items: flex-start;
    }

    ${Meta} {
        display: flex;
        flex-direction: column;
        max-width: 50%;

        ${media.tablet`
			max-width: 100%;	
		`}

        ${Title} {
            ${type.body};
            padding-bottom: 32px;

            ${media.tablet`
				padding-bottom: 20px;
			`}
        }

        ${Text} {
            font-size: 21px;

            ${media.tablet`
				font-size: 18px;	
			`}
        }
    }

    ${CardList} {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 40px;

        ${media.tablet`
			margin-bottom: 0px;
		`}
    }
`

// Data

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "our-practice" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default OurPractice
